/*!
 * Theme v1.0.0
 * Copyright 2022 Lemon Interactive
 *
 */

import 'lazysizes';
// import a plugin of lazysizes (view doc module for detail and other plugin)
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

// import './global';
// import './tarte-au-citron';
// import './webp';
// import './menu';
// import './cart';
import './layout/product';
// import './product-bundle';
import './layout/homepage';
import './header';

// add simple support for background images:
document.addEventListener('lazybeforeunveil', (e) => {
  const bg = e.target.getAttribute('data-bg');

  if (bg) {
    e.target.style.backgroundImage = `url(${bg})`;
  }
});

document.getElementById('scrollBtn').addEventListener('click', (event) => {
  event.preventDefault();
  const target = document.getElementById('contact');
  target.scrollIntoView({
    behavior: 'smooth',
  });
});
