function throttle(callback, delay) {
  let timeout = null;
  return (...args) => {
    if (!timeout) {
      timeout = setTimeout(() => {
        callback(...args);
        timeout = null;
      }, delay);
    }
  };
}

if (window.matchMedia('(min-width: 1200px)').matches) {
  const header = document.querySelector('header#header');
  if (header) {
    window.addEventListener(
      'scroll',
      throttle(() => {
        const currentScrollPos = window.scrollY;
        if (currentScrollPos > 10) {
          header.classList.add('sticky');
        } else {
          header.classList.remove('sticky');
        }
      }, 100),
    );
  }
}
